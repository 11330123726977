.header {
  background-color: white;
  display: flex;
  flex-direction: column;
  position: sticky;
  justify-content: center;
  align-items: center;
  top: 0;
  gap: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
  height: 100%;
  z-index: 1000;
}