.selected-image-item-container {
    position: relative;
    display: inline-block;
}

.selected-image-item {
    display: block;
    border-radius: 8px;
    width: 170px;
    height: 225px;
    object-fit: cover;
    flex-shrink: 0;
    border-radius: 12px;
    box-shadow: 0px 2px 5px 0px rgba(103, 110, 118, 0.08), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.selected-image-item-close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.selected-image-item-close-button:hover {
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
}