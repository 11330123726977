.results-page {
    display: grid;
    grid-template-rows: 125px 1fr 130px;
    height: 100%;
    margin: 0;
    padding: 0;
}

.results-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    gap: 20px;
}

.result-header {
    position: sticky;
    top: 0;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 500;
}

.breadcrumb {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 14px;
}

.breadcrumb a {
    text-decoration: none;
    color: #666;
}

.footer-container {
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 500;
}

.images-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.input-images {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    -webkit-touch-callout: none
}

.input-image {
    width: 170px;
    height: 225px;
    object-fit: cover;
    border-radius: 12px;
    margin: 8px;
    /* box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); */
}

.create-button {
    /* position: absolute; */
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    /* bottom: 40px; */
    width: 200px;
    height: 38px;
    padding: 3px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 30px;
    cursor: pointer;
}

.create-button img {
    width: 16px;
    height: 16px;
    object-fit: cover;
    object-position: top;
}

.create-button p {
    color: black;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    line-height: 20px;
    margin: 0;
}

.create-button:hover {
    background-color: #f0f0f0;
}