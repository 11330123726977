.result-image-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 300px;
    height: 415px;
    margin: 8px;
}

.image-container {
    background: linear-gradient(to top, #FBF2CB, #D9F9E6);
    border-radius: 10px;
    /* box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); */
    width: 300px;
    height: 400px;
}

.generated-image {
    margin-left: 4px;
    margin-top: 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    object-fit: cover;
    border-radius: 10px;
    -webkit-touch-callout: none;
    object-position: top;
    background: #edeef1 20%;
}

.top-label-container {
    position: absolute;
    top: 0px;
    background: linear-gradient(to top, #FBF2CB, #D9F9E6);
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.label {
    color: #24292E;
    padding: 5px;
    font-family: 'Inter-Medium';
    font-size: 12px;
    text-align: center;
}

.bottom-label-container {
    position: absolute;
    bottom: 0px;
    background: linear-gradient(to top, #FBF2CB, #D9F9E6);
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.shimmer-item-result {
    flex: 0 0 auto;
    margin-left: 4px;
    margin-top: 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    background: #f6f7f8;
    background-image: linear-gradient(to right,
            #f6f7f8 0%,
            #edeef1 20%,
            #f6f7f8 40%,
            #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 1000px 100%;
    overflow: hidden;
    animation: shimmer 2s infinite linear;
    border-radius: 10px;
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}