.image-uploader-container {
  background-color: white;
  border: none;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.12), 0px 15px 35px 0px rgba(103, 110, 118, 0.08);
  border-radius: 10px;
  margin: 0;
  padding: 0;
  padding-top: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  transition: width 0.2s ease;
  position: relative;
}

.image-uploader-container h3 {
  font-family: "Inter-Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

@media (min-width: 700px) {
  .image-uploader-container {
    width: 680px;
  }
}

@media (min-width: 600px) and (max-width: 699px) {
  .image-uploader-container {
    width: 580px;
  }
}

@media (min-width: 450px) and (max-width: 599px) {
  .image-uploader-container {
    width: 430px;
  }
}

@media (min-width: 350px) and (max-width: 449px) {
  .image-uploader-container {
    width: 330px;
  }
}

@media (min-width: 259px) and (max-width: 349px) {
  .image-uploader-container {
    width: 240px;
  }
}

@media (max-width: 260px) {
  .image-uploader-container {
    width: 150px;
  }
}

.image-uploader-close-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
}

.image-uploader {
  border: 1px dashed #ccc;
  background-color: #F6F7F9;
  border-radius: 10px;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
  width: calc(100% - 40px);
}

.upload-area {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F6F7F9;
  border-radius: 10px;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.upload-label {
  color: #596066;
  cursor: pointer;
  font-family: "Inter-Medium";
  font-size: 14px;
  line-height: 20px;
}

.upload-link {
  color: #007bff;
  text-decoration: underline;
  font-family: "Inter-Medium";
  font-size: 14px;
  line-height: 20px;
}

.uploaded-image {
  max-width: 100%;
  max-height: 200px;
  border-radius: 8px;
}

.search-results-horizontal-scroll {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  padding: 0px 10px;
}

.search-results-horizontal-scroll {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.search-results-horizontal-scroll::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}