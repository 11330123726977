@font-face {
  font-family: "Inter-Bold";
  src: url("./Fonts/Inter-Bold.ttf") format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: "Inter-Medium";
  src: url("./Fonts/Inter-Medium.ttf") format('truetype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "Inter-Regular";
  src: url("./Fonts/Inter-Regular.ttf") format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("./Fonts/Inter-SemiBold.ttf") format('truetype');
  font-style: normal;
  font-weight: 600;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter-Bold', 'Inter-Medium', 'Inter-Regular', 'Inter-SemiBold', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Arial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}