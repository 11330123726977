.download-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    width: calc(100% - 40px);
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    /* box-sizing: border-box; */
}

.switch-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
}

.switch {
    position: relative;
    margin-right: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
    width: 30px;
    height: 20px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #53B483;
}

input:checked+.slider:before {
    transform: translateX(10px);
}

.label-text {
    font-family: 'Inter-SemiBold';
    font-size: 12px;
    color: black
}

.download-button {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    background: linear-gradient(to top, #FBF2CB, #D9F9E6);
    border: 1px solid #CCCED1;
    cursor: pointer;
    width: 200px;
    height: 48px;
}

.download-button:hover {
    background: linear-gradient(to bottom, #FBF2CB, #D9F9E6);
}

.download-button p {
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    line-height: 28px;
}

.download-button img {
    width: 20px;
    height: 20px;
    object-fit: cover;
    object-position: top;
}