.search-celebrity {
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 20px;
  padding: 10px 14px;
  text-align: center;
  border: 1px #9EA5AD;
  border-radius: 100px;
  box-shadow: 0px 2px 5px 0px rgba(103, 110, 118, 0.08), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white;
  gap: 8px;
}

.search-input {
  border: none;
  width: 100%;
  font-weight: 400;
  font-family: 'Inter-Regular';
  font-size: 16px;
  line-height: 24px;
  outline: none;
}