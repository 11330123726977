.filter-item {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.3s ease;
    width: 124px;
    gap: 0px;
}

.filter-item img {
    margin-top: 2px;
    object-fit: cover;
    border-radius: 10px;
    transition: all 0.3s ease;
    width: 120px;
    height: 180px;
    z-index: 4;
}

.filter-item-image-background {
    background: white;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    height: 184px;
    z-index: 2;
}

.filter-item-image-background.selected {
    background: linear-gradient(to top, #FBF2CB, #D9F9E6);
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.filter-item-bottom-view {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-top: -10px;
    z-index: 0;
}

.filter-item-bottom-view.selected {
    background: linear-gradient(to top, #FBF2CB, #D9F9E6);
}

.filter-item p {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Inter-Medium';
    font-size: 13px;
    line-height: 20px;
}