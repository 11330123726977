.main-container {
    width: 100vw;
}

.main-container {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 40px;
    overflow-y: scroll;
}

.scrollable-content {
    overflow-y: hidden;
}

.main-container.sticky-header {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 40px;
    height: 100vh;
}

.scrollable-content.sticky-header {
    overflow-y: auto;
}

.header-container {
    background-color: white;
    padding: 0px;
    padding-top: 0;
    z-index: 1000;
}

.scrollable-content {
    border-top: 1px solid #ddd;
    background-color: #F6F7F9;
    padding: 0px;
}

.outside-container {
    /* position: relative; */
    display: inline-block;
}

.error-alert {
    position: fixed;
    display: flex;
    align-items: center;
    bottom: 40px;
    right: 20px;
    border-radius: 10px;
    z-index: 5000;
}

.css-ki1hdl-MuiAlert-action {
    padding: 0 0 0 16px;
}

@media (min-width: 700px) {
    .error-alert {
        width: 680px;
    }
}

@media (min-width: 600px) and (max-width: 699px) {
    .error-alert {
        width: 580px;
    }
}

@media (min-width: 450px) and (max-width: 599px) {
    .error-alert {
        width: 430px;
    }
}

@media (min-width: 350px) and (max-width: 449px) {
    .error-alert {
        width: 330px;
    }
}

@media (min-width: 259px) and (max-width: 349px) {
    .error-alert {
        width: 240px;
    }
}

@media (max-width: 260px) {
    .error-alert {
        width: 150px;
    }
}



.mixmatch-button {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;

    border-radius: 28px;
    background: linear-gradient(to top, #FBF2CB, #D9F9E6);
    border: 1px solid #CCCED1;
    box-shadow: 0px 2px 5px 0px rgba(103, 110, 118, 0.08), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);

    cursor: pointer;
    padding-left: 16px;
    padding-right: 16px;
    width: 200px;
    height: 56px;

    position: fixed;
    bottom: 40px;
    left: calc(50% - 100px);
    z-index: 2000;
}

.mixmatch-button:hover {
    background: linear-gradient(to bottom, #FBF2CB, #D9F9E6);
}

.mixmatch-button.disabled {
    background: #F6F7F9;
    cursor: default;
}

.mixmatch-button.disabled:hover {
    background: #F6F7F9;
    cursor: default;
}

.mixmatch-button p {
    font-family: 'Inter-SemiBold';
    font-size: 16px;
    line-height: 28px;
}

.mixmatch-button p.disabled {
    opacity: 0.4;
}

.mixmatch-button img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 15px;
    object-position: top;
}

.mixmatch-button img.disabled {
    opacity: 0.4;
}