.search-result-image {
    width: 170px;
    height: 225px;
    object-fit: cover;
    flex-shrink: 0;
    border-radius: 12px;
    box-shadow: 0px 2px 5px 0px rgba(103, 110, 118, 0.08), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
}

.celebrity-search-result-item-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 0px;
    cursor: pointer;
}

.celebrity-search-result-item-container p {
    font-family: "Inter-SemiBold";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}