.logo-header {
  display: flex;
  flex-direction: row;
  gap: 0px;
  margin: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
}

@media (min-width: 370px) {
  .logo-header {
    flex-direction: row;
  }
}

@media (max-width: 370px) {
  .logo-header {
    flex-direction: column;
  }
}

@media (min-width: 450px) {
  .inner-logo-title {
    font-size: 18px;
  }
}

@media (max-width: 450px) {
  .inner-logo-title {
    font-size: 13px;
  }
}

.inner-logo-title {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-family: 'Inter-Bold';
  transform: translateX(21px);
}

.logo-header-image {
  display: flex;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
}

.about-button {
  font-size: 16px;
  line-height: 24px;
  font-family: 'Inter-Regular';
  color: black;
  text-align: left;
  width: 60px;
  border: none;
}

.about-button a {
  text-decoration: none;
  color: black;
}

.inner-logo-title a {
  text-decoration: none;
  color: black;
}

.contact-button {
  font-size: 16px;
  font-family: 'Inter-Regular';
  line-height: 24px;
  color: black;
  text-align: right;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  cursor: pointer;
  width: 60px;
}