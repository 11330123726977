.title {
    margin: 20px 0;
    text-align: center;
    color: #596066;
    font-family: "Inter-Medium";
    font-size: 14px;
    line-height: 20px;
}

.separator-container {
    box-shadow: none;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
    padding-left: 10px;
    padding-right: 10px;
    /* width: 100%; */
}

.rectangle {
    height: 1px;
    width: 100%;
    border-radius: 10px;
    background-color: #D9D9D9;
}