.selected-images-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.selected-images-items-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.selected-images-add-another-image-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 0px 18px;
    gap: 8px;
    box-shadow: 0px 2px 5px 0px rgba(103, 110, 118, 0.08), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.12);
    border-radius: 100px;
    background-color: #FAFAFA;
    cursor: pointer;
}

.selected-images-add-another-image-button p {
    color: black;
    font-family: 'Inter-SemiBold';
    font-size: 14px;
    line-height: 20px;
}

.selected-images-add-another-image-button:hover {
    background-color: #E5E7EA;
}