.container-view {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding-bottom: 100px;
}

.collection-view {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
}


.horizontal-scroll {
    display: flex;
    gap: 30px;
    overflow-x: scroll;
    padding: 0px;
}

.horizontal-scroll {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.horizontal-scroll::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.filter-section {
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.horizontal-scroll-shimmer {
    display: flex;
    gap: 30px;
    overflow-x: visible;
    padding: 0px;
}

.shimmer-item {
    flex: 0 0 auto;
    margin-left: 2px;
    margin-top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #f6f7f8;
    background-image: linear-gradient(to right,
            #f6f7f8 0%,
            #edeef1 20%,
            #f6f7f8 40%,
            #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 1000px 100%;
    animation: shimmer 2s infinite linear;
    border-radius: 10px;
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}

.shimmer-item-image-background {
    background: white;
    border-radius: 10px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    width: 124px;
    height: 184px;
    z-index: 2;
}

.horizontal-section-title {
    margin: 0px;
    color: #596066;
    font-family: 'Inter-Medium';
    font-size: 14px;
    line-height: 20px;
}